import { api } from '../helpers/api-axios';

interface TokenRequest {
  userId: string;
  token: string;
}

const blockToken = async (userId: string, token: string) => {
  const payload: TokenRequest = { userId, token };
  return await api.post(`${process.env.REACT_APP_BASE_API_URL}/sim/v1/posttoken`, payload);
};

export { blockToken };
